var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.showSection("additional_products")
    ? _c(
        "b-card",
        { attrs: { "no-body": "" } },
        [
          _c(
            "b-card-header",
            {
              directives: [
                {
                  name: "b-toggle",
                  rawName: "v-b-toggle.verify-order-accordion-7",
                  modifiers: { "verify-order-accordion-7": true },
                },
              ],
              staticClass: "accordion-header",
              class: { disabled: _vm.sectionIsDisabled("additional_products") },
              attrs: { "header-tag": "header", role: "tab" },
              on: {
                "!click": function ($event) {
                  $event.stopPropagation()
                  return _vm.$emit("set-as-current")
                },
              },
            },
            [
              _c(
                "div",
                {
                  staticClass:
                    "d-flex justify-content-between align-items-center",
                },
                [
                  _c("div", { staticClass: "header-left" }, [
                    _c("h4", [
                      _vm._v("\n          Important New Items\n        "),
                    ]),
                    _c(
                      "h5",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: _vm.sectionIsComplete("additional_products"),
                            expression:
                              "sectionIsComplete('additional_products')",
                          },
                        ],
                      },
                      [
                        _c("check-circle", {
                          staticClass: "check-circle",
                          attrs: { width: "20", height: "20" },
                        }),
                        _vm._v(
                          "\n          " +
                            _vm._s(_vm.addOnProductsHeaderText) +
                            "\n        "
                        ),
                      ],
                      1
                    ),
                  ]),
                  _c("feather-icon", { attrs: { type: "chevron-right" } }),
                ],
                1
              ),
            ]
          ),
          _c(
            "b-collapse",
            {
              ref: "verify-order-accordion-7",
              attrs: {
                id: "verify-order-accordion-7",
                accordion: "verify-order-accordion",
                role: "tabpanel",
                visible: _vm.currentSection === "additional_products",
              },
            },
            [
              _vm.loaded
                ? _c(
                    "b-card-body",
                    [
                      _c("optional-products-carousel", {
                        attrs: {
                          header:
                            "These are some items other clients are adding with their order.",
                          items: _vm.addOnProducts,
                          "items-in-cart": _vm.addOnProductsInCart,
                          "remove-products": _vm.showRemoveProductsWarning,
                        },
                        on: { changed: _vm.selectedAddOnProductsChanged },
                      }),
                    ],
                    1
                  )
                : _c("ct-centered-spinner"),
              _c(
                "b-card-footer",
                {
                  staticClass: "accordion-footer",
                  class: {
                    showRemoveProductsWarning: _vm.showRemoveProductsWarning,
                  },
                  attrs: { "footer-tag": "footer", role: "tab" },
                },
                [
                  !_vm.showRemoveProductsWarning
                    ? [
                        _c(
                          "b-button",
                          {
                            staticClass: "standard-verify-button-size",
                            attrs: {
                              variant: "outline-primary",
                              "aria-label": "no thanks button",
                              disabled: _vm.removingAllAddOnProducts,
                            },
                            on: { click: _vm.declineAddOnProducts },
                          },
                          [_vm._v("\n          No Thanks\n        ")]
                        ),
                        _c(
                          "b-button",
                          {
                            staticClass: "button-continue",
                            attrs: {
                              variant: "primary",
                              "aria-label": "continue button",
                              disabled:
                                !_vm.addOnProductsInCart.length ||
                                _vm.removingAllAddOnProducts,
                            },
                            on: { click: _vm.addAddOnProductsToCartAndProceed },
                          },
                          [
                            _vm._v(
                              "\n          Continue " +
                                _vm._s(_vm.newBusinessItemsCounter) +
                                "\n        "
                            ),
                          ]
                        ),
                      ]
                    : _vm.showRemoveProductsWarning
                    ? [
                        _c(
                          "div",
                          { staticClass: "remove-products-warning-text" },
                          [
                            _vm._v(
                              "\n          By clicking Remove, the product(s) selected above will be removed from your order\n        "
                            ),
                          ]
                        ),
                        _c(
                          "b-button",
                          {
                            staticClass: "button-continue",
                            attrs: {
                              variant: "outline-primary",
                              "aria-label": "continue button",
                              disabled: _vm.removingAllAddOnProducts,
                            },
                            on: {
                              click: _vm.removeAddOnProductsFromCartAndProceed,
                            },
                          },
                          [
                            !_vm.removingAllAddOnProducts
                              ? [_vm._v("\n            Remove\n          ")]
                              : _c("b-spinner", { attrs: { small: "" } }),
                          ],
                          2
                        ),
                        _c(
                          "b-button",
                          {
                            staticClass: "button-decline",
                            attrs: {
                              variant: "primary",
                              "aria-label": "go back button",
                              disabled: _vm.removingAllAddOnProducts,
                            },
                            on: { click: _vm.cancelRemoveAddOnProducts },
                          },
                          [_vm._v("\n          Go Back\n        ")]
                        ),
                      ]
                    : _vm._e(),
                ],
                2
              ),
            ],
            1
          ),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }