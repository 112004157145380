<template>
  <b-card
    v-if="showSection('additional_products')"
    no-body
  >
    <b-card-header
      v-b-toggle.verify-order-accordion-7
      :class="{ disabled: sectionIsDisabled('additional_products') }"
      header-tag="header"
      class="accordion-header"
      role="tab"
      @click.stop.capture="$emit('set-as-current')"
    >
      <div class="d-flex justify-content-between align-items-center">
        <div class="header-left">
          <h4>
            Important New Items
          </h4>
          <h5 v-show="sectionIsComplete('additional_products')">
            <check-circle class="check-circle" width="20" height="20" />
            {{ addOnProductsHeaderText }}
          </h5>
        </div>
        <feather-icon type="chevron-right" />
      </div>
    </b-card-header>
    <b-collapse
      id="verify-order-accordion-7"
      ref="verify-order-accordion-7"
      accordion="verify-order-accordion"
      role="tabpanel"
      :visible="currentSection === 'additional_products'"
    >
      <b-card-body v-if="loaded">
        <optional-products-carousel
          header="These are some items other clients are adding with their order."
          :items="addOnProducts"
          :items-in-cart="addOnProductsInCart"
          :remove-products="showRemoveProductsWarning"
          @changed="selectedAddOnProductsChanged"
        />
      </b-card-body>
      <ct-centered-spinner v-else />
      <b-card-footer
        footer-tag="footer"
        :class="{ showRemoveProductsWarning }"
        class="accordion-footer"
        role="tab"
      >
        <template v-if="!showRemoveProductsWarning">
          <b-button
            variant="outline-primary"
            class="standard-verify-button-size"
            aria-label="no thanks button"
            :disabled="removingAllAddOnProducts"
            @click="declineAddOnProducts"
          >
            No Thanks
          </b-button>

          <b-button
            variant="primary"
            class="button-continue"
            aria-label="continue button"
            :disabled="!addOnProductsInCart.length || removingAllAddOnProducts"
            @click="addAddOnProductsToCartAndProceed"
          >
            Continue {{ newBusinessItemsCounter }}
          </b-button>
        </template>
        <template v-else-if="showRemoveProductsWarning">
          <div class="remove-products-warning-text">
            By clicking Remove, the product(s) selected above will be removed from your order
          </div>
          <b-button
            variant="outline-primary"
            class="button-continue"
            aria-label="continue button"
            :disabled="removingAllAddOnProducts"
            @click="removeAddOnProductsFromCartAndProceed"
          >
            <template v-if="!removingAllAddOnProducts">
              Remove
            </template>
            <b-spinner v-else small />
          </b-button>
          <b-button
            variant="primary"
            class="button-decline"
            aria-label="go back button"
            :disabled="removingAllAddOnProducts"
            @click="cancelRemoveAddOnProducts"
          >
            Go&nbsp;Back
          </b-button>
        </template>
      </b-card-footer>
    </b-collapse>
  </b-card>
</template>

<script>

import { mapGetters, mapActions } from 'vuex'
import {
  loadOptionalCategoryProducts,
  optionalItemSelected,
} from '@/components/HireUs/optionalItemHelper'

export default {
  name: 'ImportantNewBusinessItemsSection',
  components: {
    FeatherIcon:              () => import('@/components/shared/FeatherIcon'),
    CheckCircle:              () => import('@images/ui/check-circle.svg'),
    OptionalProductsCarousel: () => import('@/components/VerifyOrder/OptionalProductsCarousel'),
    CtCenteredSpinner:        () => import('@/components/shared/CtCenteredSpinner'),
  },
  props: {
  },
  data() {
    return {
      loaded: false,
      allAddOnProducts: [],
      selectedAddOnProducts: [],
      addOnProducts: [],
      addingUpsellProductToCart: false,
      removingAllAddOnProducts: false,
      showRemoveProductsWarning: false,
    }
  },
  computed: {
    ...mapGetters('account', ['isAccountWholesaler']),
    ...mapGetters('companies', [
      'activeComplianceServiceInJurisdiction',
    ]),
    ...mapGetters('checkout', [
      'allProducts',
      'cartItems',
      'productsByKind',
    ]),
    ...mapGetters('verifyOrder', [
      'currentSection',
      'sectionIsComplete',
      'sectionIsDisabled',
      'showSection',
      'optionalCategories',
    ]),
    ...mapGetters('schema', [
      'currentObject',
    ]),
    cartItemProductIds() {
      return this.cartItems.map(ci => ci.product_id)
    },
    cartHasRegisteredAgentService() {
      return this.cartItems.some((ci) => ci.display_info.product_category === 'registered-agent')
    },
    cartHasComplianceIncludedWithRa() {
      return this.cartItems.some(cartItem => this.productsByKind('simple_product').find(product => product.id === cartItem.product_id && product?.metadata?.filing_included_with_ra))
    },
    hasOptionalCategoriesAndProducts() {
      return this.optionalCategories.length &&
        this.allProducts?.length
    },
    addOnProductsInCart() {
      return this.cartItems.length ?
        this.allAddOnProducts.filter(p => this.cartItemProductIds.includes(p.id)) :
        []
    },
    newBusinessItemsCounter() {
      return this.addOnProductsInCart.length ?
        "(" + this.addOnProductsInCart.length + ")" :
        ""
    },
    addOnProductsHeaderText() {
      const count = this.addOnProductsInCart.length
      if (count < 1)        return 'No Items Added'
      else if (count === 1) return '1 Item Added'
      else                  return `${count} Items Added`
    },
  },
  async mounted() {
    await this.loadAddOnProducts()

    this.loaded = true
  },
  methods: {
    ...mapActions('verifyOrder', [
      'logVerifyOrderInteraction',
    ]),
    loadAddOnProducts() {
      const skipCategoryNames = ['Open and Scan']
      if (this.optionalCategories.some(oi => oi.name === 'File Annual Report For Me')) {
        const state_province_region = (typeof(this.currentObject.jurisdiction) === 'object') ?
          this.currentObject.jurisdiction.state_province_region :
          this.currentObject.jurisdiction

        if (this.skipAnnualReport(state_province_region)) {
          skipCategoryNames.push('File Annual Report For Me')
        }
      }
      if (this.hasOptionalCategoriesAndProducts) {
        [this.addOnProducts, this.allAddOnProducts] =
          loadOptionalCategoryProducts(this.optionalCategories, skipCategoryNames)
      }
    },
    selectedAddOnProductsChanged(selection) {
      this.selectedAddOnProducts = selection
    },
    async addAddOnProductsToCartAndProceed() {
      await this.logVerifyOrderInteraction('add-optional-products')
      this.$emit('completed')
    },
    async declineAddOnProducts() {
      await this.logVerifyOrderInteraction('decline-optional-products')
      if (this.addOnProductsInCart.length) {
        this.showRemoveProductsWarning = true
      } else {
        this.$emit('completed')
      }
    },
    async removeAddOnProductsFromCartAndProceed() {
      await this.logVerifyOrderInteraction('remove-add-on-products-confirmed')
      await this.removeAllAddOnProductsInCart()
      this.$emit('completed')
      this.showRemoveProductsWarning = false
    },
    async removeAllAddOnProductsInCart() {
      this.removingAllAddOnProducts = true

      for (const removeProduct of this.addOnProductsInCart) {
        await optionalItemSelected(removeProduct, false)
      }
      this.removingAllAddOnProducts = false
    },
    async cancelRemoveAddOnProducts() {
      await this.logVerifyOrderInteraction('remove-add-on-products-cancelled')
      this.showRemoveProductsWarning = false
    },
    skipAnnualReport(state_province_region) {
      return this.activeComplianceServiceInJurisdiction(state_province_region) ||
        (this.cartHasComplianceIncludedWithRa &&
          this.cartHasRegisteredAgentService &&
          !this.isAccountWholesaler)
    },
  },
}
</script>

<style lang="scss" scoped>

#verify-order-accordion-7 {

  .accordion-footer {

    &.showRemoveProductsWarning {
      background: #f0ebeb;
      .remove-products-warning-text {
        font-size: .8em;
        color: #dc3545 !important;
        font-weight: 700;
        margin-right: 10px;
      }
    }
  }
}

</style>
